<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!-- <h5 style="font-size: 1.9em; font-weight: 700">Anuncios</h5> -->
        <div class="fullscreen-container">
          <h5 style="font-size: 1.9em; font-weight: 700">Anuncios</h5>
          <BannerSlider></BannerSlider>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h5 style="font-size: 1.9em; font-weight: 700">Eventos</h5>
          <EventoSlider
            :events="events"
            :networks="networks"
            :sharing="sharing"
          ></EventoSlider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerSlider from "@/components/public/BannerSlider/index.vue";
import EventoSlider from "@/components/public/EventoSlider/index.vue";
import "hooper/dist/hooper.css";

export default {
  components: {
    BannerSlider,
    EventoSlider,
  },
  data() {
    return {
      events: [],
      sharing: {
        url: "https://ipssoma.com",
        title: "",
        description: "",
        quote: "",
        hashtags: "",
      },

      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fah fa-lg fa-linkedin",
          color: "#007bb5",
        },

        {
          network: "telegram",
          name: "Telegram",
          icon: "fab fah fa-lg fa-telegram-plane",
          color: "#0088cc",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
  methods: {
    open(network, card) {
      // this.sharing.title = card.title;
      console.log(network);
      console.log(card);
    },

    async getEvents() {
      await this.$axios.get("/public/events/list").then((response) => {
        this.events = response.data;
        console.log(response.data);
      });
    },

    change(network) {
      console.log(network);
    },
    close(network) {
      console.log(network);
    },
  },
  mounted() {
    this.getEvents();
  },
};
</script>
