<template>
  <div class="events-section">
    <div class="hooper hooper-vertical">
      <div class="hooper-wrap">
        <div class="hooper-slide" v-for="(card, index) in events" :key="index">
          <div class="event-card">
            <div
              class="event-image"
              :style="{ backgroundImage: 'url(' + card.image + ')' }"
            >
              <div class="event-title">{{ card.title }}</div>
            </div>
            <div class="event-details">{{ card.date }} - {{ card.hour }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventoSlider",
  components: {
    /* Hooper,
      Slide */
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    sharing: {
      type: Object,
      required: true,
    },
    networks: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleShare(card) {
      card.dialShare = !card.dialShare;
    },
  },
};
</script>

<style scoped>
.events-section {
  margin-top: 20px;
  padding: 0px 20px;
}

.event-card {
  margin-bottom: 20px;
}

.event-image {
  height: 200px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
}

.event-title {
  word-break: break-word;
  font-size: 1.1em;
  font-weight: 600;
  color: white;
}

.event-details {
  display: none;
  word-break: break-word;
}

.speed-dial {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.fab-button {
  background-color: #ffc107;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease;
}

.fab-button:hover {
  background-color: #ffca28;
}

.fab-button-close {
  background-color: red;
}
</style>
