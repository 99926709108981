<template>
  <div class="banner-carousel">
    <div v-if="banners && banners.length > 0">
      <carousel
        :per-page="1"
        :autoplay="true"
        :autoplayTimeout="3000"
        :loop="true"
      >
        <slide v-for="(banner, index) in banners" :key="index">
          <div class="banner-card">
            <img :src="banner.image" alt="Banner Image" class="banner-image" />
            <div class="banner-title">{{ banner.title }}</div>
          </div>
        </slide>
      </carousel>
    </div>
    <div v-else class="no-banners-message">No hay banners disponibles</div>
  </div>
</template>

<script>
import axios from "axios";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "BannerSlider",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      banners: [],
    };
  },
  mounted() {
    // Realizar la solicitud HTTP para obtener los datos de los banners
    axios
      .get("/public/banners/list")
      .then((response) => {
        this.banners = response.data;
        // Mostrar información de las imágenes en la consola
        this.banners.forEach((banner, index) => {
          console.log(`Banner ${index + 1}:`);
          console.log(`Título: ${banner.title}`);
          console.log(`URL de la imagen: ${banner.image}`);
        });
      })
      .catch((error) => {
        console.error("Error al obtener los datos de los banners:", error);
      });
  },
};
</script>

<style scoped>
.banner-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0px !important;
}

.hooper {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
}

.hooper-wrap {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s;
}

.hooper-slide {
  flex: 0 0 auto;
}

.banner-card {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 10px; /* Margen entre cada banner */
}

.banner-image {
  height: 70vh;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.banner-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
